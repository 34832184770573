<template>
    <div class="purse-box" style="width:1266px;background: #ee4d2d;margin: 0 auto;">
        <div style="padding: 40px;">
            <div style="display: flex;align-items: center;">
                <img style="width: 48px;height: 48px;margin-right: 16px;" src="/img/4.png" alt="">
                <span style="color: #fff;font-size: 30px;font-weight: bold;">Shopee官方钱包</span>
            </div>
            <div style="margin-top: 36px;display: flex;flex-wrap: wrap;width: 670px;">
                <div style="width: 335px;height: 82px;margin-bottom: 26px">
                    <div style="display: flex;align-items: center;">
                        <img class="eds-icon" src="/img/1.svg" alt="">
                        <span style="font-size: 20px;">官方一站式便捷服务</span>
                    </div>
                    <div style="margin-top: 4px;font-size: 14px;line-height: 24px;">
                        Shopee官方出品安全合规，一键绑定、多店铺收<br />款、提现、付款全覆盖！
                    </div>
                </div>
                <div style="width: 335px;height: 82px;margin-bottom: 26px;">
                    <div style="display: flex;align-items: center;">
                        <img class="eds-icon" src="/img/2.svg" alt="">
                        <span style="font-size: 20px;">提前回款缩短账期</span>
                    </div>
                    <div style="margin-top: 4px;font-size: 14px;line-height: 24px;">
                        订单发货即可申请提前回款，免征信无资料轻松解<br />决现金流压力！
                    </div>
                </div>
                <div style="width: 335px;height: 82px;margin-bottom: 26px">
                    <div style="display: flex;align-items: center;">
                        <img class="eds-icon" src="/img/3.svg" alt="">
                        <span style="font-size: 20px;">新卖家提现0费率</span>
                    </div>
                    <div style="margin-top: 4px;font-size: 14px;line-height: 24px;">
                        新注册用户享6个月提现0费率！购买权益卡更可享<br />受长期0费率权益！
                    </div>
                </div>
                <div style="width: 335px;height: 82px;margin-bottom: 26px">
                    <div style="display: flex;align-items: center;">
                        <img class="eds-icon" src="/img/4.svg" alt="">
                        <span style="font-size: 20px;">尊享更优汇率</span>
                    </div>
                    <div style="margin-top: 4px;font-size: 14px;line-height: 24px;">
                        优于中国主流银行实时现汇买入价的汇率，提现换<br />汇更省钱！
                    </div>
                </div>
            </div>
            <div style="display: flex;align-items: center;">
                <div class="btn-1">常见问题</div>
                <div class="btn-2" @click="dialogTableVisible = true">立即注册
                    <div class="son"
                        style="position: absolute;left: 0;right: 0;bottom: 0;top: 0;background: rgba(0, 0, 0, 0.1);">
                    </div>
                </div>
            </div>
        </div>
        <div>
            <img style="width: 532px;margin-left: -20px;" src="/img/5.png" alt="">
        </div>
        <el-dialog width="600px" style="margin-top: 100px;" title="虾皮服务条款" :close-on-click-modal="false"
            :show-close="false" :visible.sync="dialogTableVisible">
            <div style="color: #333;">
                在您使用Shopee官方钱包的功能和服务前，您需要阅读并同意最新版本的
                <span style="    color: #00f;
                font-style: italic;
                text-decoration: underline;">《结算业务服务<br />条款》</span>
                与
                <span style="    color: #00f;
                font-style: italic;
                text-decoration: underline;">《跨境支付结算服务协议》条款及协议</span>
                <div style="height: 16px;width: 100%;"></div>

                Before using the functions and services of Shopee Seller Wallet, you need to read and agr<br />ee to the
                terms
                and agreements of the latest version of
                <span style="    color: #00f;
                font-style: italic;
                text-decoration: underline;">"Settlement Services Terms of Se<br />rvice"</span>
                and
                <span style="    color: #00f;
                 font-style: italic;
                 text-decoration: underline;">"Cross-Border Settlement Agreement".</span>
                <br />
                <el-checkbox style="margin-top:32px ;" v-model="checked">我已阅读并确认被给予充分机会理解该使用条款的内容。</el-checkbox>
                <div style="display: flex;justify-content: end;margin-top: 24px;">
                    <el-button type="primary" :disabled="!checked" @click="toPush">接受</el-button>
                </div>
            </div>
        </el-dialog>
    </div>

</template>
<script>
    export default {
        data() {
            return {
                dialogTableVisible: false,
                checked:false
            }
        },
        methods: {
            toPush(){
                this.$router.push('purseInfo')
            }
        }
    }


</script>
<style lang="less" scoped>
    .purse-box {
        color: #fff;
        display: flex;
        align-items: center;

        .eds-icon {
            width: 30px;
            height: 30px;
            margin-right: 8px;
        }

        .btn-1 {
            width: 89px;
            line-height: 40px;
            border: 1px solid #eee;
            text-align: center;
            border-radius: 4px;
            font-size: 14px;
            cursor: pointer;
            margin-right: 16px;

        }

        .btn-1:hover {
            background: rgba(255, 255, 255, 0.1);
        }

        .btn-2 {
            width: 90px;
            line-height: 40px;
            background: #fff;
            border-radius: 4px;
            color: #ee4d2d;
            text-align: center;
            cursor: pointer;
            font-size: 14px;
            position: relative;

            .son {
                display: none;
            }
        }

        .btn-2:hover .son {
            display: block;
        }
    }
</style>