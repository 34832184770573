import request from '@/api/request.js';
// 卖家账号注册
export function open(data) {
    return request({
        url: 'admin/api/business/register',
        method: 'post',
        data: data
    });
}
// 卖家账号登陆
export function shopLogin(data) {
    return request({
        url: 'business/api/user/login',
        method: 'post',
        data: data
    });
}
// 卖家账号退出
export function logOut(data) {
    return request({
        url: 'business/api/user/logout',
        method: 'post',
        data: data
    });
}
// 卖家账号登陆
export function shopCenterInfo(data) {
    return request({
        url: 'business/api/home/index',
        method: 'post',
        data: data
    });
}
// 订单统计图标
export function getOrderStatics(data) {
    return request({
        url: 'business/api/home/getOrderStatics',
        method: 'post',
        data: data
    });
}
// 销售额统计图标
export function getSalesStatics(data) {
    return request({
        url: 'business/api/home/getSalesStatics',
        method: 'post',
        data: data
    });
}
// 公告列表
export function getArticleList(data) {
    return request({
        url: 'business/api/home/article',
        method: 'get',
        params: data
    });
}
//  公告详情
export function getArticleInfo(data) {
    return request({
        url: 'business/api/home/articleDetail',
        method: 'get',
        params: data
    });
}

//  获取商家列表
export function merchantList(data) {
    return request({
        url: 'business/api/home/merchantList',
        method: 'get',
        params: data
    });
}
//  切换商家
export function toggleMerchant(data) {
    return request({
        url: 'business/api/home/toggleMerchant',
        method: 'post',
        data: data
    });
}
//  商店介绍
export function shopInfo(data) {
    return request({
        url: 'business/api/shop/index',
        method: 'post',
        data: data
    });
}

//  编辑商家介绍
export function shopInfoEdit(data) {
    return request({
        url: 'business/api/shop/edit',
        method: 'post',
        data: data
    });
}

// 商店管理 - 6.3-获取商店评价
export function getEvaluateList(data) {
    return request({
        url: 'business/api/shop/getEvaluateList',
        method: 'post',
        data: data
    });
}

// 商店管理 - 6.4-回复评价
export function addEvaluate(data) {
    return request({
        url: 'business/api/shop/addEvaluate',
        method: 'post',
        data: data
    });
}

//  我的地址
export function myAddress(data) {
    return request({
        url: 'business/api/site/myAddress',
        method: 'get',
        params: data
    });
}
//  添加编辑地址
export function editAddress(data) {
    return request({
        url: 'business/api/site/address',
        method: 'post',
        data: data
    });
}
//  商店设定列表
export function shopConfigList(data) {
    return request({
        url: 'business/api/site/shopConfig',
        method: 'get',
        params: data
    });
}

//  修改商店设定
export function setShopConfig(data) {
    return request({
        url: 'business/api/site/setShopConfig',
        method: 'post',
        data: data
    });
}
//  修改账号/电话信息
export function setAccount(data) {
    return request({
        url: 'business/api/site/setAccount',
        method: 'post',
        data: data
    });
}
//  修改登入密码
export function changePassword(data) {
    return request({
        url: 'business/api/site/changePassword',
        method: 'post',
        data: data
    });
}
// 忘记密码

export function forgotPassword(data) {
    return request({
        url: 'business/api/user/forgotPassword',
        method: 'post',
        data: data
    });
}

// 测评中心 - 1.1-获取用户统计信息
export function getUserInfo(data) {
    return request({
        url: 'business/api/scoreSmart/getUserInfo',
        method: 'post',
        data: data
    });
}
// 测评中心 - 1.2-获取学生得分详情
export function getUserScoreDetail(data) {
    return request({
        url: 'business/api/scoreSmart/getUserScoreDetail',
        method: 'post',
        data: data
    });
}

// 测评中心 - 1.3-获取学生流程分详情
export function getUserFlowDetail(data) {
    return request({
        url: 'business/api/scoreSmart/getUserFlowDetail',
        method: 'post',
        data: data
    });
}

// 测评中心 - 1.4-获取学生运营产品详情

export function getUserOperationProductDetail(data) {
    return request({
        url: 'business/api/scoreSmart/getUserOperationProductDetail',
        method: 'post',
        data: data
    });
}

// 测评中心 - 1.5-获取学生运营订单详情

export function getUserOperationOrderDetail(data) {
    return request({
        url: 'business/api/scoreSmart/getUserOperationOrderDetail',
        method: 'post',
        data: data
    });
}


// 测评中心 - 1.6-获取学生利润分详情

export function getUserProfitDetail(data) {
    return request({
        url: 'business/api/scoreSmart/getUserProfitDetail',
        method: 'post',
        data: data
    });
}


// 测评中心 - 1.7-获取教师评分详情


export function getUserTeacherDetail(data) {
    return request({
        url: 'business/api/scoreSmart/getUserTeacherDetail',
        method: 'post',
        data: data
    });
}
// 测评中心 - 1.8-获取用户整体得分
export function getUserScoreInfo(data) {
    return request({
        url: 'business/api/scoreSmart/getUserScoreInfo',
        method: 'get',
        params: data
    });
}

// 测评中心 - 1.9-获取用户教师评分详情

export function getUserTeacherScoreDetail(data) {
    return request({
        url: 'business/api/scoreSmart/getUserTeacherScoreDetail',
        method: 'get',
        params: data
    });
}

// 测评中心 - 1.9.2-获取用户流程分详情

export function getUserFlowScoreDetail(data) {
    return request({
        url: 'business/api/scoreSmart/getUserFlowScoreDetail',
        method: 'get',
        params: data
    });
}


// 测评中心 - 1.9.3-获取用户利润分详情

export function getUserProfitScoreDetail(data) {
    return request({
        url: 'business/api/scoreSmart/getUserProfitScoreDetail',
        method: 'get',
        params: data
    });
}

// 测评中心 - 1.9.4-获取用户运营分详情


export function getUserOperationScoreDetail(data) {
    return request({
        url: 'business/api/scoreSmart/getUserOperationScoreDetail',
        method: 'get',
        params: data
    });
}

// 测评中心 - 2.1-获取总分排行榜

export function getScoreBank(data) {
    return request({
        url: 'business/api/scoreSmart/getScoreBank',
        method: 'post',
        data: data
    });
}

// 测评中心 - 2.2-获取流程分排行榜

export function getFlowBank(data) {
    return request({
        url: 'business/api/scoreSmart/getFlowBank',
        method: 'post',
        data: data
    });
}

// 测评中心 - 2.3-获取运营分排行榜

export function getOperationBank(data) {
    return request({
        url: 'business/api/scoreSmart/getOperationBank',
        method: 'post',
        data: data
    });
}

// 测评中心 - 2.4-获取利润分排行榜


export function getProfitBank(data) {
    return request({
        url: 'business/api/scoreSmart/getProfitBank',
        method: 'post',
        data: data
    });
}

// 测评中心 - 2.5-获取教师评分排行榜

export function getTeacherBank(data) {
    return request({
        url: 'business/api/scoreSmart/getTeacherBank',
        method: 'post',
        data: data
    });
}


// 测评中心 - 2.6-得分排行榜
export function getUserBank(data) {
    return request({
        url: 'business/api/scoreSmart/getUserBank',
        method: 'post',
        data: data
    });
}

// 商店管理 - 1.6-获取店铺装修
export function getDecoration(data) {
    return request({
        url: 'business/api/shop/getDecoration',
        method: 'get',
        params: data
    });
}

// 商店管理 - 1.7-店铺装修保存
export function decoration(data) {
    return request({
        url: 'business/api/shop/decoration',
        method: 'post',
        data: data
    });
}

// 商店管理 - 1.9-店铺装修保存/发布

export function subDecoration(data) {
    return request({
        url: 'business/api/shop/subDecoration',
        method: 'post',
        data: data
    });
}



// 0-8-设置管理 | 2.1-获取商家市场汇率设置

export function shopExchangeRate(data) {
    return request({
        url: 'business/api/site/shopExchangeRate',
        method: 'get',
        params: data
    });
}


// 0-8-设置管理 | 2.2-保存商家市场汇率

export function saveExchangeRate(data) {
    return request({
        url: 'business/api/site/saveExchangeRate',
        method: 'post',
        data: data
    });
}



