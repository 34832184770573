<template>
    <div class="purse-info">
        <div class="head">
            <img src="/img/logo.png" style="width: 28px;margin-right: 16px;" alt="">
            <el-breadcrumb style="font-size: 16px;" separator-class="el-icon-arrow-right">
                <el-breadcrumb-item>主页</el-breadcrumb-item>
                <el-breadcrumb-item>Shopee官方钱包</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div style="overflow-y: scroll;height: calc( 100vh - 80px);">
            <div style="width: 100%;height: 16px;background: #f6f6f6;    position: fixed;
            left: 0;
            right: 0;
            top: 76px;
            z-index: 2;"></div>
            <div class="cell">
                <div class="content">
                    <div v-if="step==0"
                        style="margin-top: 20px;display: flex;flex-direction: column;justify-content: space-between;    height: 100%;">
                        <div>
                            <div style="font-size: 20px;padding: 24px 0 16px;border-bottom: 1px solid #eee;">请选择公司注册地区
                            </div>
                            <div style="margin-top: 40px;">
                                <div @click="radio='1'"
                                    style="cursor: pointer; border: 1px solid #E5E5E5;width: 540px;display: flex;align-items: center;border-radius: 4px;height: 80px;margin: 0 auto;">
                                    <div style="padding-left: 8px;"> <el-radio
                                            style="margin-right: -10px;padding: 0 8px;" v-model="radio" label="1">
                                            <div></div>
                                        </el-radio></div>
                                    <div style="width: 1px;height: 45px;background: #E5E5E5;margin: 0 8px;"></div>
                                    <div
                                        style="display: flex;align-items: center;justify-content: space-between;width: 100%;padding: 0 16px;">
                                        <span>中国内地</span>
                                        <img style="width: 72px;height: 72px;" src="/img/zgnd.jpg" alt="">
                                    </div>
                                </div>
                                <div @click="radio='2'"
                                    style="cursor: pointer;  border: 1px solid #E5E5E5;width: 540px;display: flex;align-items: center;border-radius: 4px;height: 80px;margin: 20px  auto 0;">
                                    <div style="padding-left: 8px;"> <el-radio
                                            style="margin-right: -10px;padding: 0 8px;" v-model="radio" label="2">
                                            <div></div>
                                        </el-radio></div>
                                    <div style="width: 1px;height: 45px;background: #E5E5E5;margin: 0 8px;"></div>
                                    <div
                                        style="display: flex;align-items: center;justify-content: space-between;width: 100%;padding: 0 16px;">
                                        <span>香港</span>
                                        <img style="width: 72px;height: 72px;" src="/img/xg.jpg" alt="">
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div style="padding-top: 40px;color: #999;font-size: 14px;">
                            根据您已经同意的《结算业务服务条款》，您在此页面填写的信息将由Shopee平台签约合作的结算服务提供商圳星科技有限公司负责收集和处理。
                        </div>
                    </div>
                    <div v-if="step==1">
                        <div style="width: 944px;margin-top: 20px;">
                            <el-form ref="form" :model="form" :rules="rules" label-width="200px">
                                <div
                                    style="font-size: 20px;padding: 24px 0 16px;border-bottom: 1px solid #eee;margin-bottom: 24px;">
                                    公司信息
                                </div>
                                <div>
                                    <el-form-item label="公司证件类型">
                                        <el-radio-group v-model="form.gszjlx">
                                            <el-radio :disabled="form.gszjlx=='2'" label="1">中国内地营业执照</el-radio>
                                            <el-radio :disabled="form.gszjlx=='1'" label="2">中国香港商业登记文件</el-radio>
                                        </el-radio-group>
                                        <div style="color: #00000075;font-size: 14px;">
                                            如证件类型选择有误，请点击左下角的上一步按钮，返回“选择注册地区”页面重新选择。
                                        </div>
                                    </el-form-item>
                                    <el-form-item label="营业执照" prop="name">
                                        <UploadFiles :ids="form.name" :imageUrl="form.name" :states="1" ref="tp1">
                                        </UploadFiles>
                                        <div style="font-size: 12px;color: #999;">
                                            仅可上传1张图片，支持jpg/jpeg, png格式，最大不超过3M
                                        </div>
                                    </el-form-item>

                                    <el-form-item label="公司中文名称">
                                        <el-input style="width: 550px;" v-model="form.name"
                                            placeholder="请输入"></el-input>
                                    </el-form-item>
                                    <el-form-item label="企业性质">
                                        <el-select style="width: 550px;" v-model="form.region" placeholder="请选择">
                                            <el-option label="区域一" value="shanghai"></el-option>
                                            <el-option label="区域二" value="beijing"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="统一社会信用代码">
                                        <el-input style="width: 550px;" v-model="form.name"
                                            placeholder="请输入"></el-input>
                                    </el-form-item>
                                    <el-form-item label="公司成立日期">
                                        <el-date-picker style="width: 550px;" v-model="value1" type="date"
                                            placeholder="请选择">
                                        </el-date-picker>
                                    </el-form-item>
                                    <el-form-item label="证件有效期">
                                        <el-radio-group v-model="form.gszjlx">
                                            <el-radio label="1">非长期有效</el-radio>
                                            <el-radio label="2">长期有效</el-radio>
                                        </el-radio-group>

                                    </el-form-item>
                                    <el-form-item label="公司营业期限">
                                        <el-date-picker style="width: 550px;" v-model="value1" type="date"
                                            placeholder="请选择">
                                        </el-date-picker>
                                    </el-form-item>
                                    <el-form-item label="注册地址">
                                        <el-input style="width: 550px;" v-model="form.name"
                                            placeholder="请输入"></el-input>
                                    </el-form-item>
                                    <el-form-item label="企业经营范围">
                                        <el-select style="width: 550px;" v-model="form.region" placeholder="请选择">
                                            <el-option label="区域一" value="shanghai"></el-option>
                                            <el-option label="区域二" value="beijing"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>

                                <div
                                    style="font-size: 20px;padding: 24px 0 16px;border-bottom: 1px solid #eee;margin-bottom: 24px;">
                                    法定代表人信息 </div>
                                <div>
                                    <el-form-item label="身份证件类型">
                                        <el-select style="width: 550px;" v-model="form.region" placeholder="请选择">
                                            <el-option label="区域一" value="shanghai"></el-option>
                                            <el-option label="区域二" value="beijing"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="身份证件照片">
                                        <el-select style="width: 550px;" v-model="form.region" placeholder="请选择">
                                            <el-option label="区域一" value="shanghai"></el-option>
                                            <el-option label="区域二" value="beijing"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="姓名">
                                        <el-input style="width: 550px;" v-model="form.name"
                                            placeholder="请输入"></el-input>
                                    </el-form-item>
                                    <el-form-item label="身份证件号码">
                                        <el-input style="width: 550px;" v-model="form.name"
                                            placeholder="请输入"></el-input>
                                    </el-form-item>
                                    <el-form-item label="证件发行时间">
                                        <el-date-picker style="width: 550px;" v-model="value1" type="date"
                                            placeholder="请选择">
                                        </el-date-picker>
                                    </el-form-item>
                                    <el-form-item label="证件有效期">
                                        <el-radio-group v-model="form.gszjlx">
                                            <el-radio label="1">非长期有效</el-radio>
                                            <el-radio label="2">长期有效</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                    <el-form-item label="证件到期时间">
                                        <el-date-picker style="width: 550px;" v-model="value1" type="date"
                                            placeholder="请选择">
                                        </el-date-picker>
                                    </el-form-item>
                                    <el-form-item label="性别">
                                        <el-radio-group v-model="form.gszjlx">
                                            <el-radio label="1">男</el-radio>
                                            <el-radio label="2">女</el-radio>
                                        </el-radio-group>

                                    </el-form-item>
                                    <el-form-item label="出生日期">
                                        <el-date-picker style="width: 550px;" v-model="value1" type="date"
                                            placeholder="请选择">
                                        </el-date-picker>
                                    </el-form-item>
                                    <el-form-item label="国别（地区）">
                                        <el-select style="width: 550px;" v-model="form.region" placeholder="请选择">
                                            <el-option label="区域一" value="shanghai"></el-option>
                                            <el-option label="区域二" value="beijing"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="常住国家（地区）">
                                        <el-select style="width: 550px;" v-model="form.region" placeholder="请选择">
                                            <el-option label="区域一" value="shanghai"></el-option>
                                            <el-option label="区域二" value="beijing"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="常住地址">
                                        <el-input type="textarea" style="width: 550px;" v-model="form.name"
                                            placeholder="请输入"></el-input>
                                    </el-form-item>
                                </div>

                                <div style="font-size: 20px;padding: 24px 0 16px;border-bottom: 1px solid #eee;">
                                    受益人信息
                                </div>
                                <div style="display: flex;">
                                    <div style="width: 200px;text-align: center;">受益人</div>
                                    <div>
                                        <div
                                            style="background: #fafafa;margin-bottom: 20px;border-radius: 8px;padding: 24px;display: flex;font-size: 14px;justify-content: space-between;margin-top: 20px;align-items: center;">
                                            <div style="color: #999;">
                                                如果法人是最终受益人（之一），您可点击导入法人信息快速添加，如果还存在其他最终受<br />益人，您可继续添加</div>
                                            <div style="font-weight: bold;color: #2673dd;cursor: pointer;">导入法人信息</div>
                                        </div>
                                        <div style="background: #fafafa;width: 728px;border-radius: 8px;padding: 24px;">
                                            <el-form-item label="身份证件类型">
                                                <el-select style="width: 360px;" v-model="form.region"
                                                    placeholder="请选择">
                                                    <el-option label="区域一" value="shanghai"></el-option>
                                                    <el-option label="区域二" value="beijing"></el-option>
                                                </el-select>
                                            </el-form-item>
                                            <el-form-item label="身份证件照片">
                                                <el-select style="width: 360px;" v-model="form.region"
                                                    placeholder="请选择">
                                                    <el-option label="区域一" value="shanghai"></el-option>
                                                    <el-option label="区域二" value="beijing"></el-option>
                                                </el-select>
                                            </el-form-item>
                                            <el-form-item label="姓名">
                                                <el-input style="width: 360px;" v-model="form.name"
                                                    placeholder="请输入"></el-input>
                                            </el-form-item>
                                            <el-form-item label="身份证件号码">
                                                <el-input style="width: 360px;" v-model="form.name"
                                                    placeholder="请输入"></el-input>
                                            </el-form-item>
                                            <el-form-item label="证件发行时间">
                                                <el-date-picker style="width: 360px;" v-model="value1" type="date"
                                                    placeholder="请选择">
                                                </el-date-picker>
                                            </el-form-item>
                                            <el-form-item label="证件有效期">
                                                <el-radio-group v-model="form.gszjlx">
                                                    <el-radio label="1">非长期有效</el-radio>
                                                    <el-radio label="2">长期有效</el-radio>
                                                </el-radio-group>
                                            </el-form-item>
                                            <el-form-item label="证件到期时间">
                                                <el-date-picker style="width: 360px;" v-model="value1" type="date"
                                                    placeholder="请选择">
                                                </el-date-picker>
                                            </el-form-item>
                                            <el-form-item label="性别">
                                                <el-radio-group v-model="form.gszjlx">
                                                    <el-radio label="1">男</el-radio>
                                                    <el-radio label="2">女</el-radio>
                                                </el-radio-group>

                                            </el-form-item>
                                            <el-form-item label="出生日期">
                                                <el-date-picker style="width: 360px;" v-model="value1" type="date"
                                                    placeholder="请选择">
                                                </el-date-picker>
                                            </el-form-item>
                                            <el-form-item label="国别（地区）">
                                                <el-select style="width: 360px;" v-model="form.region"
                                                    placeholder="请选择">
                                                    <el-option label="区域一" value="shanghai"></el-option>
                                                    <el-option label="区域二" value="beijing"></el-option>
                                                </el-select>
                                            </el-form-item>
                                            <el-form-item label="常住国家（地区）">
                                                <el-select style="width: 360px;" v-model="form.region"
                                                    placeholder="请选择">
                                                    <el-option label="区域一" value="shanghai"></el-option>
                                                    <el-option label="区域二" value="beijing"></el-option>
                                                </el-select>
                                            </el-form-item>
                                            <el-form-item label="常住地址">
                                                <el-input type="textarea" style="width: 360px;" v-model="form.name"
                                                    placeholder="请输入"></el-input>
                                            </el-form-item>
                                        </div>
                                        <div
                                            style="cursor: pointer; border-radius: 4px;margin-top: 20px; font-size: 14px; border: 1px dotted #2673dd;height: 32px;display: flex;align-items: center;justify-content: center;color: #2673dd;">
                                            <span style="padding-right: 20px;">┼</span> 添加受益人（1/4）
                                        </div>
                                    </div>

                                </div>
                            </el-form>
                        </div>
                        <div
                            style="margin-top: 20px; height: 48px;background: #fafafa;display: flex;align-items: center;padding: 0 20px;">
                            <el-checkbox size v-model="checked" style="color: #000;">我已确认上述信息填写无误</el-checkbox>
                        </div>
                        <div style="padding-top: 40px;color: #999;font-size: 14px;">
                            根据您已经同意的《结算业务服务条款》，您在此页面填写的信息将由Shopee平台签约合作的结算服务提供商圳星科技有限公司负责收集和处理。
                        </div>
                    </div>
                    <div v-if="step==2" style="    padding-top: 140px;
                    text-align: center;
                ">
                        *您的实名认证审核一般需要2个工作日，
                        <br />
                        在审核通过后，您就可以使用Shopee官方钱包。
                    </div>

                </div>
                <div class="top">
                    <div style=" border-bottom: 1px solid #eee;">
                        <div style="display: flex;align-items: center;justify-content: center;width: 100%;">
                            <div v-for="item,index in 3" :key="index" style="display:flex;align-items: center;">
                                <div class="round" :style="{background:step>=index||index==0?'#ee4d2d':'#BFBFBF'}">
                                </div>
                                <div v-if="index!=2" class="line"
                                    :style="{background:step>=index+1?'#ee4d2d':'#e8e8e8'}">
                                </div>
                            </div>
                        </div>
                        <div
                            style="display: flex;align-items: center;margin-top: 8px;width: 600px;margin: 16px auto 0;padding-bottom: 28px;">
                            <div>选择注册地区</div>
                            <div :style="{color:step>=1?'#000':'#00000073'}"
                                style="    margin-left: 158px;margin-right: 167px;">填写相关信息</div>
                            <div :style="{color:step>=2?'#000':'#00000073'}">提交审核</div>

                        </div>
                    </div>

                </div>
                <div class="bottom">

                    <div
                        style="margin: 0 24px; border-top: 1px solid #eee;display: flex;align-items: center;justify-content: space-between;background: #fff;height: 80px;padding: 0 24px;">

                        <div> <el-button @click="handlePrev" v-if="step==1">上一步</el-button></div>
                        <!-- <el-button>保存</el-button> -->
                        <el-button type="primary" @click="handleNext" v-if="step==0">下一步</el-button>
                        <el-button type="primary" @click="handleNext" v-if="step==1">提交</el-button>
                        <el-button type="primary" @click="handleNext" v-if="step==2">完成</el-button>
                    </div>
                    <div style="width: 100%;height: 40px;background: #f6f6f6;"></div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
    import UploadFiles from '@/components/UploadFiles'
    export default {
        components: {

            UploadFiles,
        },
        data() {
            return {
                radio: '1',
                step: 0,
                form: {
                    name: '',
                    gszjlx: '1'
                },
                rules: {
                    name: [
                        { required: true, message: '请输入活动名称', trigger: 'blur' },
                        { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
                    ],
                }
            }

        },
        methods: {
            handleNext() {
                this.step++
                this.form.gszjlx=this.radio
            },
            handlePrev() {
                this.step--
            }
        },
    }

</script>
<style lang="less" scoped>
    .purse-info {
        min-height: 100vh;
        background: #f6f6f6;



        .head {
            display: flex;
            align-items: center;
            background: #fff;
            box-shadow: 0 1px 4px rgba(0, 0, 0, .12);
            font-size: 16px;
            margin-bottom: 16px;
            padding: 16px 24px;

        }

        .cell {
            width: calc(100% - 102px);
            margin-left: auto;
            margin-right: auto;
            background: #fff;
            position: relative;



            .content {
                width: 944px;
                margin: 140px auto 0;
                padding-bottom: 150px;
                min-height: calc(100vh - 200px);

            }

            .top {
                position: fixed;
                background: #fff;
                top: 92px;
                left: 51px;
                right: 57px;
                padding-top: 40px;
                padding-bottom: 20px;

                padding-left: 24px;
                padding-right: 24px;

                .round {
                    width: 8px;
                    height: 8px;
                    border-radius: 8px;
                    background: #ee4d2d;
                    margin: 0 4px;
                }

                .line {
                    width: 231px;
                    height: 3px;
                    background: #e8e8e8;

                }
            }

            .bottom {

                position: fixed;
                bottom: 0;
                left: 52px;
                right: 56px;

            }
        }
    }
</style>