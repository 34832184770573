export default {
    path: '/',
    component: () => import('@/layout/NoLayout.vue'),
    redirect: 'myAddress',
    name: "myAddressz",
    meta: {
        title: '设置',
        layout: 'NoLayout',
        userType: 2,
        name: "设置",
        pageType: 1,
    },
    children: [
        {
            path: 'myAddress',
            component: () => import('@/views/seller/shop/setUp/myAddress.vue'),
            name: "myAddress",
            meta: {
                title: '我的地址',
                layout: 'LayShopCenter',
                userType: 2,
                validation: true,
                pageType: 1,
                bread: [
                    { name: "首页", path: "shopCenter" },
                    { name: "我的地址", path: false },
                ]
            },
        },
        {
            path: 'merchantSet',
            component: () => import('@/views/seller/shop/setUp/merchantSet.vue'),
            name: "merchantSet",
            meta: {
                title: '商家设置',
                layout: 'LayShopCenter',
                userType: 2,
                validation: true,
                pageType: 1,
                bread: [
                    { name: "首页", path: "shopCenter" },
                    { name: "商家设置", path: false },
                    { name: "全球商品设置", path: false },
                    { name: "市场汇率设置", path: false },
                ]
            },
        },
        {
            path: 'storeSet',
            component: () => import('@/views/seller/shop/setUp/storeSet.vue'),
            name: "storeSet",
            meta: {
                title: '商店设定',
                layout: 'LayShopCenter',
                userType: 2,
                validation: true,
                pageType: 1,
                bread: [
                    { name: "首页", path: "shopCenter" },
                    { name: "商店设定", path: false },
                ]
            },
        },
        {
            path: 'accountSet',
            component: () => import('@/views/seller/shop/setUp/accountSet.vue'),
            name: "accountSet",
            meta: {
                title: '账户设定',
                layout: 'LayShopCenter',
                userType: 2,
                validation: true,
                pageType: 1,
                bread: [
                    { name: "首页", path: "shopCenter" },
                    { name: "账户设定", path: false },
                ]
            },
        },
        {
            path: 'scoreCenter',
            component: () => import('@/views/seller/shop/review/scoreCenter.vue'),
            name: "scoreCenter",
            meta: {
                title: '查看得分',
                layout: 'LayShopCenter',
                userType: 2,
                validation: true,
                pageType: 1,
                bread: [
                    { name: "首页", path: "shopCenter" },
                    { name: "查看得分", path: false },
                ]
            },
        },
        {
            path: 'scoreRank',
            component: () => import('@/views/seller/shop/review/scoreRank.vue'),
            name: "scoreRank",
            meta: {
                title: '查看排名',
                layout: 'LayShopCenter',
                userType: 2,
                validation: true,
                pageType: 1,
                bread: [
                    { name: "首页", path: "shopCenter" },
                    { name: "查看排名", path: false },
                ]
            },
        },

    ]
}