<template>
  <div class="myAddress">
    <div class="cell">
      <div class="title">
        <div>
          <span>我的地址</span>
          <div class="describe">管理您的运费及快递取货地址</div>
        </div>
        <el-button type="primary" icon="el-icon-plus" @click="
          dialogVisible = true;
        acc = true;
        ">新增地址</el-button>
      </div>
      <div v-if="httpsShow">
        <div v-if="addressList.length != 0">
          <div class="content" v-for="item in addressList" :key="item.id">
            <div style="display: flex; justify-content: space-between">
              <div style="width: 4%; text-align: center">
                <i style="font-size: 30px; color: #00bfa5" class="el-icon-location-information"></i>
              </div>
              <div style="width: 92%">
                <div style="display: flex">
                  <div style="width: 15%; color: #7a7a7a">姓名</div>
                  <div style="display: flex; align-items: center">
                    {{ item.name }}
                    <el-tag size="small" color="#E8F9F7" v-if="item.default_addr == 1" style="
                        border-color: #e8f9f7;
                        color: #40d0bd;
                        margin-left: 10px;
                      ">个人默认地址</el-tag>
                    <el-tag size="small" color="#FFF1F0" v-if="item.pickup_addr == 1" style="
                        border-color: #fff1f0;
                        color: #ee4d2d;
                        margin-left: 10px;
                      ">取货地址</el-tag>
                  </div>
                </div>
                <div style="display: flex; margin-top: 10px">
                  <div style="width: 15%; color: #7a7a7a">电话号码</div>
                  <div>(+86){{ item.mobile }}</div>
                </div>
                <div style="display: flex; margin-top: 10px">
                  <div style="width: 15%; color: #7a7a7a">收件地址</div>
                  <div style="line-height: 28px">

                    {{ item.country }} {{ item.address }}
                    <br />
                    {{ item.zip_code }}
                  </div>
                </div>
              </div>
              <div style="font-size: 16px; color: #2673dd" @click="handleEdit(item)">
                编辑
              </div>
            </div>
            <div></div>
          </div>
        </div>
        <div v-else>
          <el-empty description="暂无地址"></el-empty>
        </div>
      </div>
      <div v-else style="text-align: center; padding-top: 20px">
        <img :src="lodingImg" alt="" />
      </div>
    </div>

    <el-dialog title="添加新地址" :visible.sync="dialogVisible" width="600px" :before-close="handleClose"
      :close-on-click-modal="false">
      <div id="box" style="
          height: 500px;
          padding: 0 10px;
          padding-bottom: 20px;
          overflow: scroll;
          margin-top: -30px;
        ">
        <el-form label-position="top" label-width="80px" :model="formLabelAlign" ref="resetForm">
          <el-form-item label="姓名" prop="name">
            <el-input v-model="formLabelAlign.name"></el-input>
          </el-form-item>
          <el-form-item label="国家地区" prop="country">
            <!-- <el-input v-model="formLabelAlign.country" maxlength="20" show-word-limit></el-input> -->
            <el-select style="width: 100%;" v-model="formLabelAlign.country" placeholder="请选择">
              <el-option v-for="item,index in list" :key="index" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="电话号码" prop="mobile">
            <el-input v-model="formLabelAlign.mobile">
              <template slot="prepend">+86</template>
            </el-input>
          </el-form-item>
          <!-- <div>收货地址</div> -->
          <!-- <el-form-item label="城市/地区">
            <el-input v-model="formLabelAlign.city_code"></el-input>
            <el-cascader
              v-if="acc"
              style="width: 100%"
              v-model="value"
              :options="options"
              @change="handleChange"
            ></el-cascader>
            <el-cascader
              v-if="!acc && value.length != 0"
              style="width: 100%"
              v-model="value"
              :options="options"
              @change="handleChange"
            ></el-cascader>
          </el-form-item> -->
          <el-form-item label="详细地址" prop="address">
            <el-input :rows="3" type="textarea" v-model="formLabelAlign.address" :maxlength="100"
              show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="邮政编码" prop="zip_code">
            <el-input v-model="formLabelAlign.zip_code"></el-input>
          </el-form-item>
          <el-checkbox style="margin-bottom: 10px" v-model="formLabelAlign.default_addr" :true-label="1"
            :false-label="0">设为预设地址</el-checkbox>
          <br />
          <el-checkbox style="margin-bottom: 10px" :true-label="1" :false-label="0"
            v-model="formLabelAlign.pickup_addr">设为取货地址</el-checkbox>
          <br />
          <el-checkbox style="margin-bottom: 10px" :true-label="1" :false-label="0"
            v-model="formLabelAlign.buyer_return_addr">设定为买家退货地址</el-checkbox>
          <br />
          <el-checkbox style="margin-bottom: 10px" :true-label="1" :false-label="0"
            v-model="formLabelAlign.seller_return_addr">设定为卖家退货地址</el-checkbox>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="saveAddress">储存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import { myAddress, editAddress } from "@/api/shop.js";
  import areaList from "@/assets/js/areaList.js";
  export default {
    data() {
      return {
        addressList: [],
        dialogVisible: false,
        formLabelAlign: {
          id: 0,
          name: "",
          mobile: "",
          mobile_prefix: "+86",
          country: "",
          province: "",
          city: "",
          district: "",
          province_code: "",
          city_code: "",
          district_code: "",
          address: "",
          zip_code: "",
          default_addr: 0,
          pickup_addr: 0,
          buyer_return_addr: 0,
          seller_return_addr: 0,
        },

        options: [],
        value: [],
        acc: false,
        httpsShow: false,
        lodingImg: require("@/assets/img/app/http.gif"),
        list: [{
          name: '中国大陆',
          id: 1
        }]
      };
    },
    created() {
      this.getAddress();
      // this.getArea();
    },
    methods: {
      getAddress() {
        this.httpsShow = false;
        myAddress({
          page_size: 100,
        }).then((res) => {
          if (res.code == 1) {
            this.httpsShow = true;
            this.addressList = res.data.list;
          }
        });
      },
      getArea() {
        Object.keys(areaList.province_list).forEach((item, i) => {
          this.options[i] = {
            value: item + "," + areaList.province_list[item],
            label: areaList.province_list[item],
            children: [],
          };
          Object.keys(areaList.city_list).forEach((val) => {
            let cityId = item.slice(0, 2);
            if (cityId == val.slice(0, 2)) {
              this.options[i].children.push({
                value: val + "," + areaList.city_list[val],
                label: areaList.city_list[val],
                children: [],
              });
            }
          });
        });
        this.options.forEach((item) => {
          item.children.forEach((val) => {
            Object.keys(areaList.county_list).forEach((vals) => {
              let cityId = val.value.slice(0, 4);
              if (cityId == vals.slice(0, 4)) {
                val.children.push({
                  value: vals + "," + areaList.county_list[vals],
                  label: areaList.county_list[vals],
                });
              }
            });
          });
        });
      },
      handleClose() {
        this.formLabelAlign = {
          id: 0,
          name: "",
          mobile: "",
          mobile_prefix: "+86",
          country: "中国",
          province: "",
          city: "",
          district: "",
          province_code: "",
          city_code: "",
          district_code: "",
          address: "",
          zip_code: "",
          default_addr: 0,
          pickup_addr: 0,
          buyer_return_addr: 0,
          seller_return_addr: 0,
        };
        this.value = [];
        let box = document.getElementById("box");
        box.scrollTop = 0;
        this.dialogVisible = false;
        this.acc = false;
      },
      handleChange(value) {
        this.formLabelAlign.province_code = value[0].split(",")[0];
        this.formLabelAlign.province = value[0].split(",")[1];
        this.formLabelAlign.city_code = value[1].split(",")[0];
        this.formLabelAlign.city = value[1].split(",")[1];
        this.formLabelAlign.district_code = value[2].split(",")[0];
        this.formLabelAlign.district = value[2].split(",")[1];
      },
      saveAddress() {
        editAddress({
          ...this.formLabelAlign,
        }).then((res) => {
          if (res.code == 1) {
            this.$message.success(res.message);
            this.handleClose();
            this.getAddress();
          }
        });
      },
      handleEdit(value) {
        this.dialogVisible = true;
        this.formLabelAlign = JSON.stringify(value);
        this.formLabelAlign = JSON.parse(this.formLabelAlign);
        this.value[0] =
          this.formLabelAlign.province_code + "," + this.formLabelAlign.province;
        this.value[1] =
          this.formLabelAlign.city_code + "," + this.formLabelAlign.city;
        this.value[2] =
          this.formLabelAlign.district_code + "," + this.formLabelAlign.district;
      },
    },
  };
</script>
<style lang="less" scoped>
  .myAddress {
    .cell {
      padding: 30px 0;
      background: #fff;
      box-shadow: 0 1px 4px 0 rgba(220, 220, 220, 0.6);

      .title {
        width: 100%;
        font-size: 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 30px;
        padding-bottom: 30px;
        border-bottom: 1px solid #e8e8e8;
      }

      .describe {
        font-size: 14px;
        color: #999;
        margin-top: 10px;
      }

      .content {
        padding: 30px;
        border-bottom: 1px solid #e8e8e8;
      }
    }
  }
</style>