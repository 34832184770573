<template>
    <div class="merchant-set">
        <div
            style="position: relative;line-height: 44px;font-weight: bold;color: #ee4d2d;width: 104px;text-align: center;margin-bottom: 20px;">
            全球商品设置
            <div
                style="width: 92px;height: 3px;background: #ee4d2d;border-radius: 2px;position: absolute;bottom: 0;left: 0;right: 0;margin: 0 auto;">
            </div>
        </div>
        <div class="cell">
            <div style="border-bottom: 1px solid #eee;margin-bottom: 20px;">
                <div
                    style="position: relative;line-height: 44px;font-weight: bold;color: #ee4d2d;width: 92px;text-align: center;font-size: 14px;">
                    市场汇率设置
                    <div
                        style="width: 92px;height: 3px;background: #ee4d2d;border-radius: 2px;position: absolute;bottom: 0;left: 0;right: 0;margin: 0 auto;">
                    </div>
                </div>
            </div>
            <div style="border:1px solid #eee;border-radius: 4px;">
                <div
                    style="border-bottom: 1px solid #eee;background: #f6f6f6;display: flex;font-size: 14px;color: #666;">
                    <div style="width: 25%;padding: 10px 8px 11px 24px;">市场</div>
                    <div style="width: 50%;padding: 10px 8px 11px ;">市场汇率列表</div>
                    <div style="width: 25%;padding: 10px 16px 11px 8px;">操作</div>
                </div>
                <div style="display: flex;align-items: center;">
                    <div style="width: 25%; padding: 16px 24px;">马来虾皮</div>
                    <div style="width: 50%; padding: 16px 24px 14px 8px;">
                        1 CNY = <span v-if="!is_edit">{{info.exchange_rate}}MR</span>
                        <el-input v-else style="width: 300px;" size="small" placeholder="请输入" v-model="value">
                            <div slot="suffix" style="line-height: 34px;padding: 0 10px;">MR</div>
                        </el-input>
                    </div>
                    <div style="width: 25%; padding: 16px 24px 16px 8px;">

                        <span v-if="!is_edit" style="color: #2673dd;cursor: pointer;" @click="handleEdit">编辑</span>
                        <div v-else>
                            <el-button size="medium" style="line-height: 10px;" @click="is_edit=false">取消</el-button>
                            <el-button :loading="loading" type="primary" size="medium" style="line-height: 10px;"
                                @click='submit'>保存</el-button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
    import { shopExchangeRate, saveExchangeRate } from "@/api/shop.js";

    export default {
        data() {
            return {
                info: {
                    id: 0,
                    shop_id: this.$store.state.shop_info.shop_info.id,
                    name: "马来虾皮",
                    exchange_rate: "0.00",
                    unit: "CNY",
                    exchange_unit: "MR"
                },
                is_edit: false,
                loading: false,
                value: '',

            }
        },
        mounted() {
            this.getInfo()
        },
        methods: {
            getInfo() {
                shopExchangeRate(
                    {
                        shop_id: this.$store.state.shop_info.shop_info.id,
                    }
                ).then(res => {
                    if (res.code == 1 && res.data[0]) {
                        this.info = res.data[0]
                    }

                })
            },
            handleEdit() {
                this.is_edit = true
                this.value = this.info.exchange_rate
            },
            submit() {
                this.loading = true
                let obj = JSON.parse(JSON.stringify(this.info))
                obj.exchange_rate = this.value
                saveExchangeRate(obj).then(res => {
                    if (res.code == 1) {
                        this.info.exchange_rate = this.value
                        this.is_edit = false
                        this.$message.error('保存成功');
                    }
                }).finally(() => {
                    this.loading = false
                })

            }
        }
    }
</script>
<style lang="less" scoped>
    .merchant-set {
        .cell {
            background: #fff;
            padding: 0 24px;
            box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .12);
            border-radius: 6px;
            padding-bottom: 20px;
        }
    }
</style>