<template>
  <div>
    <div v-if="show&&$route.name == 'home'"
      style="position: fixed;top: 0;background: #fff;left: 0;right:0;z-index: 999;">
      <div id="is-temporary-tips-box"
        style="position: relative; display: block; line-height: 1.3; margin: 10px; padding: 25px 100px 25px 25px; border: 3px solid rgb(245, 108, 108); border-radius: 6px; font-size: 26px; letter-spacing: 1px; color: rgb(245, 108, 108); background-color: rgba(245, 108, 108, 0.1);">
        该站点为{{$store.state.baseInfo.company_name}}Shopee实战教学系统，用于学校Shopee实战教学。
        <a href="javascript:;"
          style="position: absolute; right: 15px; top: 50%; margin-top: -9px; color: #999; font-size: 14px;"
          @click="show=false">点击关闭</a>
      </div>
    </div>
    <div class="main-head header-wrapper"
      :style="{ position: $route.name == 'home' ? 'fixed' : '',top:show?'108px':'0' }">
      <Navbar> </Navbar>
      <HeaderWithSearch></HeaderWithSearch>
    </div>
    <div style=" weidth: 100px" :style="{height:show?'220px':'120px'}" v-if="$route.name == 'home'"></div>
  </div>
</template>
<script>
  import HeaderWithSearch from "../Main/HeaderWithSearch.vue";
  import Navbar from "../Main/Navbar.vue";
  export default {
    components: {
      HeaderWithSearch,
      Navbar,
    },
    data() {
      return {
        show: true
      }
    },
    mounted() {
      let that = this;
      setTimeout(function () {
        that.show = false
      }, 10000)
    },

  };
</script>
<style lang="less" scoped>
  .header-wrapper {
    background: linear-gradient(-180deg, #f53d2d, #f63);
    top: 0;
    left: 0;
    right: 0;
    z-index: 11;
  }
</style>