<template>
  <div class="shopMenu">
    <div style="height: auto; padding-bottom: 60px">
      <div v-for="son, index in menuList" :key="index" style="margin-bottom:24px">
        <div class="item" @click="handleOpen(son, index)">
          <div> <i style="font-size:16px;margin-right: 8px;" :class="son.icon"></i><span :style="{
            color: index == 0 && $store.state.path == '/' ? '#ee4d2d' : '#000'
          }">{{ son.name }}</span></div>
          <div class="icon" v-if="son.childList">
            <i class="el-icon-arrow-down" style="transition: transform .2s ease,-webkit-transform .2s ease;" :style="{
              transform: son.open ? 'rotate(180deg)' : 'rotate(0deg)'
            }"></i>




          </div>
        </div>
        <div v-if="son.open" style="font-size:13px;padding-left:24px">
          <div class="menu-item" :class="$store.state.path == val.key ? 'menu-item-active' : ''"
            @click="handeSelect(son.key, val.key)" v-for="val, indexs in son.childList" :key="indexs"
            style="line-height: 28px;">{{
            val.name
            }}</div>
        </div>
      </div>
      <!-- <el-menu
        :default-active="$store.state.path"
        class="el-menu-vertical-demo"
        @open="handleOpen"
        @close="handleClose"
        @select="handeSelect"
        style="border: none"
      >
        <el-menu-item index="shopCenter">
          <i class="el-icon-house"></i>
          <span slot="title">首页1</span>
        </el-menu-item>
        <el-submenu index="meLogistics">
          <template slot="title">
            <i class="el-icon-box"></i>
            <span>物流</span>
          </template>
          <el-menu-item index="meLogistics">我的物流</el-menu-item>
          <el-menu-item index="batchDelivery">批次出货</el-menu-item>
          <el-menu-item index="logisticsSet">物流设置</el-menu-item>
        </el-submenu>
        <el-submenu index="orderList">
          <template slot="title">
            <i class="el-icon-tickets"></i>
            <span>订单</span>
          </template>
          <el-menu-item index="orderList">我的订单</el-menu-item>
          <el-menu-item index="6">取消</el-menu-item>
          <el-menu-item index="7">退货/退款</el-menu-item>
        </el-submenu>
        <el-submenu index="myGoods">
          <template slot="title">
            <i class="el-icon-shopping-bag-2"></i>
            <span>商品</span>
          </template>
          <el-menu-item index="globalGoods">全球商品</el-menu-item>
          <el-menu-item index="myGoods">我的商品</el-menu-item>
          <el-menu-item index="addGoods">添加商品</el-menu-item>
        </el-submenu>
        <el-submenu index="myIncome">
          <template slot="title">
            <i class="el-icon-wallet"></i>
            <span>财务</span>
          </template>
          <el-menu-item index="myIncome">我的收入</el-menu-item>
          <el-menu-item index="bankAccount">银行账户</el-menu-item>
        </el-submenu>
        <el-submenu index="storeIntroduce">
          <template slot="title">
            <i class="el-icon-shopping-cart-full"></i>
            <span>商店</span>
          </template>
          <el-menu-item index="storeIntroduce">商店介绍</el-menu-item>
          <el-menu-item index="storeDecorate">商店装饰</el-menu-item>
          <el-menu-item index="storeEvaluation">商店评价</el-menu-item>
        </el-submenu>
        <el-submenu index="marketingCenter">
          <template slot="title">
            <i class="el-icon-c-scale-to-original"></i>
            <span>营销中心</span>
          </template>
          <el-menu-item index="marketingCenter">营销中心</el-menu-item>
          <el-menu-item index="coupons">优惠券</el-menu-item>
          <el-menu-item index="discount">折扣活动</el-menu-item>
          <el-menu-item index="suit">套装优惠</el-menu-item>
          <el-menu-item index="limit">限时选购</el-menu-item>
          <el-menu-item index="advertising">Shopee广告</el-menu-item>
        </el-submenu>
        <el-submenu index="newScore">
          <template slot="title">
            <i class="el-icon-medal-1"></i>
            <span>测评中心</span>
          </template>
          <el-menu-item index="scoreCenter">查看得分</el-menu-item>
          <el-menu-item index="scoreRank">查看排名</el-menu-item>
        </el-submenu>
        <el-submenu index="myAddress">
          <template slot="title">
            <i class="el-icon-setting"></i>
            <span>设置</span>
          </template>
          <el-menu-item index="myAddress">我的地址</el-menu-item>
          <el-menu-item index="storeSet">商店设定</el-menu-item>
          <el-menu-item index="accountSet">账户</el-menu-item>
        </el-submenu>
      </el-menu> -->
    </div>
  </div>
</template>
<script>
  export default {
    name: "ShopMenu",
    data() {
      return {
        menuList: [
          {
            name: '首页',
            icon: 'el-icon-house',
            key: 'shopCenter'
          },
          {
            name: '物流',
            open: false,
            icon: 'el-icon-box',
            key: 'meLogistics',
            childList: [
              {
                name: '我的物流',
                key: 'meLogistics'
              },
              {
                name: '批次出货',
                key: 'batchDelivery'
              },
              {
                name: '物流设置',
                key: 'logisticsSet'
              }
            ]
          },
          {
            name: '订单',
            open: false,
            icon: 'el-icon-tickets',
            key: 'orderList',
            childList: [
              {
                name: '我的订单',
                key: 'orderList'
              },
              {
                name: '已取消',
                key: '6'
              },
              {
                name: '退货/退款',
                key: '7'
              }
            ]
          },
          {
            name: '商品',
            open: false,
            icon: 'el-icon-shopping-bag-2',
            key: 'myGoods',
            childList: [
              {
                name: '全球商品',
                key: 'globalGoods'
              },
              {
                name: '我的商品',
                key: 'myGoods'
              },
              {
                name: '添加商品',
                key: 'addGoodsInfo'
              }
            ]
          },
          {
            name: '财务',
            open: false,
            icon: 'el-icon-wallet',
            key: 'myIncome',
            childList: [
              {
                name: '我的收入',
                key: 'myIncome'
              },
              {
                name: 'Shopee官方钱包',
                key: 'purse'
              },
              {
                name: '银行账户',
                key: 'bankAccount'
              }
            ]
          },
          {
            name: '商店',
            open: false,
            icon: 'el-icon-shopping-cart-full',
            key: 'storeIntroduce',
            childList: [
              {
                name: '商店介绍',
                key: 'storeIntroduce'
              },
              {
                name: '商店装饰',
                key: 'storeDecorate'
              },
              {
                name: '商店评价',
                key: 'storeEvaluation'
              }
            ]
          },
          {
            name: '营销中心',
            open: false,
            icon: 'el-icon-c-scale-to-original',
            key: 'marketingCenter',
            childList: [
              {
                name: '营销中心',
                key: "marketingCenter"
              },
              {
                name: 'Shopee广告',
                key: 'advertising'
              },
              {
                name: '优惠券',
                key: 'coupons'
              },
              // {
              //   name: '折扣活动',
              //   key: 'discount'
              // },
              // {
              //   name: '套装优惠',
              //   key: 'suit'
              // },
              // {
              //   name: '限时选购',
              //   key: 'limit'
              // },

            ]
          },
          {
            name: '设置',
            open: false,
            icon: 'el-icon-setting',
            key: 'myAddress',
            childList: [
              {
                name: '我的地址',
                key: 'myAddress',
              },
              {
                name: '商家设置',
                key: 'merchantSet'
              },
              {
                name: '商店设定',
                key: 'storeSet'
              },
              {
                name: '账户',
                key: 'accountSet'
              }
            ]
          },
          {
            name: '测评中心',
            open: false,
            icon: 'el-icon-medal-1',
            key: 'newScore',
            childList: [
              {
                name: '查看得分',
                key: 'scoreCenter'
              },
              {
                name: '查看排名',
                key: 'scoreRank'
              }
            ]
          },

        ]
      };
    },
    created() {
      this.menuList.forEach(item => {
        if (this.$store.state.openMenu.indexOf(item.key) != -1) {
          item.open = true
        }
      })
    },
    methods: {
      handleOpen(son, index) {
        if (son.childList) {
          if (!son.open) {
            this.$store.state.openMenu.push(son.key)
          } else {

            this.$store.state.openMenu.splice(this.$store.state.openMenu.indexOf(son.key), 1)
          }
          son.open = son.open ? false : true
        } else {
          if (this.$route.name != 'shopCenter') {
            this.$store.state.path = son.key;
            this.$router.push(son.key);
          }


        }

      },
      handleClose() { },
      handeSelect(key, value) {

        let path = this.$route.path;
        this.$store.state.path = value;
        this.value = value;
        if (value == "orderList") {
          this.$store.state.index = "0";
        }
        if (value == 'meLogistics') {
          this.$store.state.wlIndex = '2'
        }

        if (value == "6" || value == "7") {
          if (path != "/orderList") {
            this.$router.push("orderList");
          }
          this.$store.state.index = value;
          return false;
        }

        if ("/" + value != path) {
          if (value == "addGoodsInfo") {
            sessionStorage.ADD_GOODS_TYPE = 1;
            this.$router.options.routes.forEach((item) => {
              if (item.name == "myGoodsz") {
                item.children.forEach((val) => {
                  if (val.name == "addGoods") {
                    val.meta.bread[1].name = "全球商品";
                    val.meta.bread[1].path = "globalGoods";
                  }
                  if (val.name == "addGoodsInfo") {
                    val.meta.bread[1].name = "全球商品";
                    val.meta.bread[1].path = "globalGoods";
                  }
                });
              }
            });
            this.$store.state.path = "globalGoods";
          }


          this.$router.push(value);
        }
      },


    },
  };
</script>
<style lang="less" scoped>
  .shopMenu {
    width: 224px;
    height: 100%;
    background: #fff;
    position: fixed;
    z-index: 2;
    overflow: scroll;
    padding: 16px 16px 0;

    .item {
      color: #999;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      margin-bottom: 4px;
    }

    .icon {
      color: #000;
    }

    .item:hover .icon {
      color: #ee4d2d;
    }

    .menu-item {
      cursor: pointer;
      color: #000;
    }

    .menu-item-active {
      color: #ee4d2d;
      font-weight: bold;
    }

    .menu-item:hover {
      color: #ee4d2d;

    }
  }

  .shopMenu::-webkit-scrollbar {
    /*隐藏滚轮*/
    display: none;
  }
</style>