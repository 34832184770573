export default {
    path: '/',
    component: () => import('@/layout/NoLayout.vue'),
    redirect: 'myIncome',
    name: "myIncomez",
    meta: {
        title: '财务',
        layout: 'NoLayout',
        userType: 2,
        name: "商品",
        pageType: 1,
    },
    children: [
        {
            path: 'myIncome',
            component: () => import('@/views/seller/shop/financial/myIncome.vue'),
            name: "myIncome",
            meta: {
                title: '我的收入',
                layout: 'LayShopCenter',
                userType: 2,
                validation: true,
                pageType: 1,
                bread: [
                    { name: "首页", path: "shopCenter" },
                    { name: "我的进账", path: false },
                ]
            },
        },
        {
            path: 'purse',
            component: () => import('@/views/seller/shop/financial/purse.vue'),
            name: "purse",
            meta: {
                title: 'Shopee官方钱包',
                layout: 'LayShopCenter',
                userType: 2,
                validation: true,
                pageType: 1,
                bread: [
                    { name: "首页", path: "shopCenter" },
                    { name: "Shopee官方钱包", path: false },
                ]
            },
        },
        {
            path: 'bankAccount',
            component: () => import('@/views/seller/shop/financial/bankAccount.vue'),
            name: "bankAccount",
            meta: {
                title: '商品分类',
                layout: 'LayShopCenter',
                userType: 2,
                validation: true,
                pageType: 1,
                bread: [
                    { name: "首页", path: "shopCenter" },
                    { name: "我的钱包", path: "myIncome" },
                    { name: "银行账户", path: false },
                ]
            },
        },
        {
            path: 'simuPayLogin',
            component: () => import('@/views/seller/shop/financial/simuPayLogin.vue'),
            name: "simuPayLogin",
            meta: {
                title: '模拟支付登录',
                layout: 'NoLayout',
                userType: 2,
                validation: true,
                pageType: 1,

            },
        },
        {
            path: 'purseInfo',
            component: () => import('@/views/seller/shop/financial/purseInfo.vue'),
            name: "purseInfo",
            meta: {
                title: 'Shopee官方钱包',
                layout: 'NoLayout',
                userType: 2,
                validation: true,
                pageType: 1,

            },
        },

    ]
}